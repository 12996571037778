<template>
  <div class="upload-popup__item uploadBtn uploadBtn__grid mb-8" :class="{'upload-popup__item_first': !label }" >
    <p class="upload-popup__label" v-if="label">{{ label }}</p>
    <div class="fileUpload_fill">
      <div class="fileUpload_into">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7071 5.29289C21.0976 5.68342 21.0976 6.31658 20.7071 6.70711L9.70711 17.7071C9.31658 18.0976 8.68342 18.0976 8.29289 17.7071L3.29289 12.7071C2.90237 12.3166 2.90237 11.6834 3.29289 11.2929C3.68342 10.9024 4.31658 10.9024 4.70711 11.2929L9 15.5858L19.2929 5.29289C19.6834 4.90237 20.3166 4.90237 20.7071 5.29289Z" fill="#18B97D"/>
        </svg>
        <span>{{ name }}</span>
      </div>
      <span v-if="sizeMb" class="fileUpload__size">{{ sizeMb }} {{$t('claim_mb')}}</span>
    </div>
    <div class="uploadBtn__delete" @click="$emit('delete')">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 6C2 5.44772 2.44772 5 3 5H7V7H9V5H15V7H17V5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H3C2.44772 7 2 6.55228 2 6Z" fill="#526C82" fill-opacity="0.8"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 3C9.73478 3 9.48043 3.10536 9.29289 3.29289C9.10536 3.48043 9 3.73478 9 4V7H15V4C15 3.73478 14.8946 3.48043 14.7071 3.29289C14.5196 3.10536 14.2652 3 14 3H10ZM17 7V4C17 3.20435 16.6839 2.44129 16.1213 1.87868C15.5587 1.31607 14.7956 1 14 1H10C9.20435 1 8.44129 1.31607 7.87868 1.87868C7.31607 2.44129 7 3.20435 7 4V7H4V20C4 20.7957 4.31607 21.5587 4.87868 22.1213C5.44129 22.6839 6.20435 23 7 23H17C17.7957 23 18.5587 22.6839 19.1213 22.1213C19.6839 21.5587 20 20.7957 20 20V7C20 7 18.5523 7 18 7H17ZM6 7V20C6 20.2652 6.10536 20.5196 6.29289 20.7071C6.48043 20.8946 6.73478 21 7 21H17C17.2652 21 17.5196 20.8946 17.7071 20.7071C17.8946 20.5196 18 20.2652 18 20V7H6Z" fill="#526C82" fill-opacity="0.8"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadButtonAcceptState',
  props: {
    name: { type: String },
    sizeMb: { type: String },
    label: { default: '' }
  },
  emits: ['delete']
}
</script>
